



































import { Vue, Component, Prop } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import { Rss } from '@/modules/portal/domain/Rss';
import { MediaType } from '@/modules/creative/domain/MediaType';
import { configuration } from '@/config';
import { Items } from '@/modules/portal/domain/Items';

@Component({
    components: { Card },
})
export default class PortalNeotvRssTable extends Vue {
    @Prop() private readonly rss!: Rss;
    private readonly mediaUrl: string = configuration.MEDIA_URL;

    private getMediaUrlFor(items: Items) {
        if (items.enclosures.length > 0) {
            if (items.enclosures[0].type === MediaType.VID) {
                return `${this.mediaUrl}/video-placeholder.jpg`;
            }
            return `${items.enclosures[0].url}`;
        }
    }

    private getType(items: Items) {
        if (items.enclosures.length > 0) {
            return `${items.enclosures[0].type}`;
        }
    }

    private getDuration(items: Items) {
        if (items.enclosures.length > 0) {
            return `${items.enclosures[0].duration}`;
        }
    }
}
