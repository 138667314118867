























import { Vue, Component, Prop } from 'vue-property-decorator';
import { PortalZone } from '../domain/PortalZone';

@Component
export default class PortalNeotvTable extends Vue {
    @Prop() private readonly stations!: PortalZone[];
    private name: string = '';

    private deleteStation(station: PortalZone) {
        this.$confirm('Voulez vous vraiment supprimer cette station ?', 'Attention').then(() => {
            this.$emit('delete', station);
        }).catch(() => null);
    }

    private editStation(station: PortalZone) {
        this.$emit('edit', station);
    }

    private showStationCampaign(station: PortalZone) {
        this.$emit('showStationCampaign', station);
    }

    private search() {
        this.$emit('search', this.name);
    }
}
