






















import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import Card from '@/components/card/Card.vue';
import PortalForm from '../components/PortalForm.vue';
import { PortalTarget } from '../domain/PortalTarget';
import PortalNeotvForm from '../components/PortalNeotvForm.vue';
import PortalBannerForm from '../components/PortalBannerForm.vue';
import PortalNeotvRssTable from '../components/PortalNeotvRssTable.vue';
import { PortalZone } from '../domain/PortalZone';
import { Id } from '@/modules/user/domain/Types';
import { PortalService } from '../services/PortalService';
import { RssService } from '../services/RssService';
import { Rss } from '@/modules/portal/domain/Rss';

export interface SavePortalEvent {
    id?: Id;
    name: string;
    target: PortalTarget;
    zones: PortalZone[];
}

@Component({
    components: { PortalNeotvRssTable, Page, Card, PortalForm, PortalNeotvForm, PortalBannerForm},
})
export default class CreatePortal extends Vue {
    private isLoading: boolean = false;
    private hasSubmitted: boolean = false;

    private givenPortalId: string = '';

    private target: PortalTarget = PortalTarget.NEOTV;
    private name: string = '';
    private portalZones: PortalZone[] = [];
    private rss: Rss | Rss = {
      items: [{
        id: '',
        enclosures: [],
    }]};

    private async mounted() {
        if (this.$route.params.portalId) {
            this.givenPortalId = this.$route.params.portalId;
            await this.fetchPortal();
        }
    }

    private async fetchPortal() {
        this.isLoading = true;
        try {
            const { target, name, zones } = await PortalService
                .getPortalBy(this.givenPortalId, this.$store.getters['sessionStore/token']);
            this.target = target;
            this.portalZones = zones;
            this.name = name;
        } catch {
            this.$router.push('/portal-listing');
        }
        this.isLoading = false;
    }

    private async savePortal() {
        this.hasSubmitted = true;
        this.isLoading = true;
        if (this.hasName) {
           await this.performPortalSavingAction();
        }
        this.isLoading = false;
    }

    private async performPortalSavingAction() {
        if (!this.formIsInEditMode) {
            await this.performPortalCreation();
        } else {
            await this.performPortalUpdate();
        }
    }

    private async performPortalUpdate() {
        try {
            const portalEvent: SavePortalEvent = {
                id: this.givenPortalId,
                name: this.name,
                target: this.target,
                zones: this.portalZones,
            };

            await PortalService.update(portalEvent, this.$store.getters['sessionStore/token']);
            this.$notify.success({
                title: 'Succès',
                message: 'Les informations ont été mises à jours',
            });
            await this.fetchPortal();
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message: 'Il y a eu une erreur de la sauvegarde ! Veuillez vérifier vos champs et réessayer.',
            });
        }
    }

    private async performPortalCreation() {
        try {
            const portalEvent: SavePortalEvent = {
                name: this.name,
                target: this.target,
                zones: this.portalZones,
            };

            await PortalService.save(portalEvent, this.$store.getters['sessionStore/token']);
            this.$router.push('/portal-listing');
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message: 'Il y a eu une erreur de la sauvegarde ! Veuillez vérifier vos champs et réessayer.',
            });
        }
    }

    private onZoneUpdate(zones: PortalZone[]) {
        this.portalZones = zones;
        this.savePortal();
    }

    private onNameUpdate(name: string) {
        this.name = name;
    }

    private onTargetUpdate(target: PortalTarget) {
        this.target = target;
        this.portalZones = [];
    }

    get formIsInEditMode() {
        return Boolean(this.givenPortalId);
    }

    get hasName() {
        return Boolean(this.name);
    }

    private async onShowStationCampaign(station: PortalZone) {
        if (station.code !== undefined) {
          this.rss = await RssService.getPubs(station.code);
        }
    }
}
