





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { PortalZone } from '../domain/PortalZone';

@Component
export default class PortalBannerTable extends Vue {
    @Prop() private readonly banners!: PortalZone[];
    private name: string = '';

    private deleteBanner(banner: PortalZone) {
        this.$confirm('Voulez vous vraiment supprimer ce bandeau ?', 'Attention').then(() => {
            this.$emit('delete', banner);
        }).catch(() => null);
    }

    private editBanner(banner: PortalZone) {
        this.$emit('edit', banner);
    }

    private search() {
        this.$emit('search', this.name);
    }

    private formatDimensions(banner: PortalZone) {
        if (banner.height === 0 && banner.width === 0) {
            return 'N/A';
        }

        return `${banner.width}x${banner.height}`;
    }
}
