























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import PortalBannerTable from './PortalBannerTable.vue';
import Field from '@/components/Field.vue';
import { Id } from '../../user/domain/Types';
import { PortalZone } from '../domain/PortalZone';

@Component({
    components: { Card, PortalBannerTable, Field },
})
export default class PortalBannerForm extends Vue {
    @Prop() private readonly givenPortalBanners!: PortalZone[];

    private banners: PortalZone[] = [];
    private hasSubmitted: boolean = false;

    private name: string = '';
    private width: number = 0;
    private height: number = 0;

    private bannerToEditId: Id = '';
    private nameFilter: string = '';

    private addBanner() {
        this.hasSubmitted = true;
        if (this.name) {
            if (!this.submittedBannerAlreadyExists()) {
                this.performBannerCreation();
            } else {
                this.$message.error('Vous ne pouvez pas ajouter deux bannières avec le même nom.');
            }
        }
    }

    private performBannerCreation() {
        this.banners.push({name: this.name, width: this.width, height: this.height});
        this.performPostSuccessfulSubmitActions();
    }

    private editBanner() {
        this.hasSubmitted = true;
        if (this.name) {
            if (!this.submittedBannerAlreadyExists()) {
                const banner = this.banners.find((s) => s.id === this.bannerToEditId);
                if (banner) {
                    this.performBannerEditFor(banner);
                }
            } else {
                this.$message.error(
                    'Vous ne pouvez pas ajouter deux bannières avec le même nom.');
            }
        }
    }

    private performBannerEditFor(banner: PortalZone) {
        const index = this.banners.indexOf(banner);
        const updatedBanner = { id: this.bannerToEditId, name: this.name, width: this.width, height: this.height };
        Object.assign(this.banners[index], updatedBanner);
        this.performPostSuccessfulSubmitActions();
    }

    private performPostSuccessfulSubmitActions() {
        this.$emit('updateZone', this.banners);
        this.hasSubmitted = false;
        this.resetFields();
    }

    private cancelEdit() {
        this.resetFields();
    }

    private resetFields() {
        this.bannerToEditId = '';
        this.name = '';
        this.width = 0;
        this.height = 0;
    }

    private onBannerEdit(banner: PortalZone) {
        if (banner.id && banner.width && banner.height) {
            this.bannerToEditId = banner.id;
            this.name = banner.name;
            this.width = banner.width;
            this.height = banner.height;
        }
    }

    private onSearchUpdate(nameFilter: string) {
        this.nameFilter = nameFilter;
    }

    private onBannerDelete(banner: PortalZone) {
        this.cancelEdit();
        this.banners = this.banners.filter((b) => b.name !== banner.name);
        this.$emit('updateZone', this.banners);
    }

    private submittedBannerAlreadyExists() {
        if (this.hasBannerToEdit) {
            return this.bannerIsNotItself();
        }

        return this.banners.some((banner) => banner.name === this.name);
    }

    private bannerIsNotItself() {
        const banners = this.banners.filter((banner) => banner.name === this.name);
        if (banners.length === 1) {
            return banners[0].id !== this.bannerToEditId;
        }

        return banners.length !== 0;
    }

    @Watch('givenPortalBanners')
    private onParentPortalBannersUpdate() {
        this.banners = this.givenPortalBanners;
    }

    get hasBannerToEdit() {
        return Boolean(this.bannerToEditId);
    }

    get filteredBanners() {
        if (!this.nameFilter) {
            return this.banners;
        }

        return this.banners.filter(
            (banners) =>
                banners.name
                    .toLowerCase()
                    .indexOf(this.nameFilter.toLowerCase()) !== -1);
    }

    get hasName() {
        return Boolean(this.name);
    }
}
