

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import PortalNeotvTable from './PortalNeotvTable.vue';
import Field from '@/components/Field.vue';
import { Id } from '../../user/domain/Types';
import { PortalZone } from '../domain/PortalZone';
import { PortalTarget } from '@/modules/portal/domain/PortalTarget';

@Component({
    components: { Card, PortalNeotvTable, Field },
})
export default class PortalNeotvForm extends Vue {
    @Prop() private readonly givenPortalStations!: PortalZone[];
    @Prop() private readonly givenTarget!: PortalTarget;

    private hasSubmitted: boolean = false;
    private nameFilter: string = '';
    private stationToEditId: Id = '';
    private name: string = '';
    private code?: string = '';
    private lastPingDate?: string = '';
    private activePing?: boolean = false;
    private origin?: string = '';
    private token?: string = '';
    private stations: PortalZone[] = [];

    private addStation() {
        this.hasSubmitted = true;
        if (this.name) {
            if (!this.submittedStationExists()) {
                this.performStationCreation();
            } else {
                this.$message.error('Vous ne pouvez pas ajouter deux stations avec le même nom.');
            }
        }
    }

    private performStationCreation() {
        this.stations.push(
            {
                name: this.name,
                code: this.code,
                activePing: this.activePing,
                origin: this.origin,
                token: this.token,
            });
        this.performPostSuccessfulSubmitActions();
    }

    private editStation() {
        this.hasSubmitted = true;
        if (this.name) {
            if (!this.submittedStationExists()) {
                const station = this.stations.find((s) => s.id === this.stationToEditId);

                if (station) {
                    this.performStationEditFor(station);
                }
            } else {
                this.$message.error(
                    'Vous ne pouvez pas ajouter deux stations avec le même nom.');
            }
        }
    }

    private performStationEditFor(station: PortalZone) {
        const index = this.stations.indexOf(station);
        Object.assign(this.stations[index],
     {
                    id: this.stationToEditId,
                    name: this.name,
                    code: this.code,
                    activePing: this.activePing ,
                     origin: this.origin,
                     token: this.token,
            });
        this.performPostSuccessfulSubmitActions();
    }

    private cancelEdit() {
        this.resetFields();
    }

    private performPostSuccessfulSubmitActions() {
        this.$emit('updateZone', this.stations);
        this.hasSubmitted = false;
        this.resetFields();
    }

    private resetFields() {
        this.name = '';
        this.code = '';
        this.stationToEditId = '';
        this.activePing = false;
        this.lastPingDate = '';
        this.origin = '';
        this.token = '';
    }

    private submittedStationExists() {
        if (this.hasStationToEdit) {
            return this.stationIsNotItself();
        }

        return this.stations.some((station) => station.name === this.name);
    }

    private stationIsNotItself() {
        const stations = this.stations.filter((station) => station.name === this.name);
        if (stations.length === 1) {
            return stations[0].id !== this.stationToEditId;
        }

        return stations.length !== 0;
    }

    private onStationEdit(station: PortalZone) {
        if (station.id) {
            this.stationToEditId = station.id;
            this.name = station.name;
            this.code = station.code;
            this.activePing = station.activePing;
            this.lastPingDate = station.lastPingDate;
            this.origin = station.origin;
            this.token = station.token;
        }
    }

    private onStationDelete(station: PortalZone) {
        this.cancelEdit();
        this.stations = this.stations.filter((s) => s.name !== station.name);
        this.$emit('updateZone', this.stations);
    }

    private onNameFilterUpdate(nameFilter: string) {
        this.nameFilter = nameFilter;
    }

    @Watch('givenPortalStations')
    private onParentPortalStationsUpdate() {
        this.stations = this.givenPortalStations;
    }

    get filteredStations() {
        if (!this.nameFilter) {
            return this.stations;
        }

        return this.stations.filter(
            (station) => station.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) !== -1);
    }

    get targetIsNeoTv() {
        return this.givenTarget === PortalTarget.NEOTV;
    }

    get hasName() {
        return Boolean(this.name);
    }

    get hasStationToEdit() {
        return Boolean(this.stationToEditId);
    }

    private showStationCampaign(station: PortalZone) {
        this.$emit('showStationCampaign', station);
    }
}
