import { ApiInstance } from '@/api/ApiInstance';
import { configuration } from '@/config';
import { Rss } from '@/modules/portal/domain/Rss';

export class RssService {
    private static readonly api = ApiInstance.createFor(`${configuration.GATEWAY_URL}/rss/code`);

    public static async getPubs(code: string) {
        const {data: response} = await this.api
            .get<Rss>(`/${code}`);
        return response;
    }
}
